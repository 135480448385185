import React from "react";

function GroupIcon(props:any) {
    return (
        <svg width="24" height="24" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6949 6.88786C29.6925 9.58442 27.505 11.7687 24.8084 11.7671C22.1119 11.7656 19.9269 9.5787 19.9277 6.88214C19.9285 4.18558 22.1147 2 24.8113 2C26.1071 2.00057 27.3495 2.51586 28.2654 3.43251C29.1812 4.34916 29.6954 5.59208 29.6949 6.88786Z" 
            stroke="currentColor" {...props} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.8502 18.9692H19.7787C16.26 19.0797 13.4648 21.9641 13.4648 25.4845C13.4648 29.0049 16.26 31.8893 19.7787 31.9999H29.8502C33.3688 31.8893 36.164 29.0049 36.164 25.4845C36.164 21.9641 33.3688 19.0797 29.8502 18.9692V18.9692Z" 
            stroke="currentColor" {...props} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0462 10.6229C42.045 12.6854 40.3722 14.3566 38.3097 14.3558C36.2472 14.355 34.5758 12.6826 34.5762 10.6201C34.5766 8.55758 36.2487 6.8858 38.3112 6.8858C39.3021 6.8858 40.2525 7.2796 40.953 7.98052C41.6535 8.68143 42.0467 9.63199 42.0462 10.6229V10.6229Z" 
            stroke="currentColor" {...props} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00195 10.6229C7.00195 12.6857 8.67417 14.3579 10.737 14.3579C12.7997 14.3579 14.4719 12.6857 14.4719 10.6229C14.4719 8.56009 12.7997 6.88788 10.737 6.88788C8.67417 6.88788 7.00195 8.56009 7.00195 10.6229V10.6229Z" 
            stroke="currentColor" {...props} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.364 19.1547C38.6736 19.1547 38.114 19.7144 38.114 20.4047C38.114 21.0951 38.6736 21.6547 39.364 21.6547V19.1547ZM42.1647 20.4047L42.1906 19.155C42.182 19.1548 42.1733 19.1547 42.1647 19.1547V20.4047ZM42.1647 30.3711V31.6211C42.1733 31.6211 42.182 31.6211 42.1906 31.6209L42.1647 30.3711ZM39.364 29.1211C38.6736 29.1211 38.114 29.6808 38.114 30.3711C38.114 31.0615 38.6736 31.6211 39.364 31.6211V29.1211ZM9.68166 21.6548C10.372 21.6548 10.9317 21.0952 10.9317 20.4048C10.9317 19.7144 10.372 19.1548 9.68166 19.1548V21.6548ZM6.88094 20.4048V19.1548C6.8723 19.1548 6.86366 19.1549 6.85503 19.1551L6.88094 20.4048ZM6.88094 30.3712L6.85503 31.621C6.86366 31.6211 6.8723 31.6212 6.88094 31.6212V30.3712ZM9.68166 31.6212C10.372 31.6212 10.9317 31.0616 10.9317 30.3712C10.9317 29.6809 10.372 29.1212 9.68166 29.1212V31.6212ZM39.364 21.6547H42.1647V19.1547H39.364V21.6547ZM42.1388 21.6545C44.1705 21.6966 45.7956 23.3557 45.7956 25.3879H48.2956C48.2956 21.9952 45.5826 19.2253 42.1906 19.155L42.1388 21.6545ZM45.7956 25.3879C45.7956 27.4202 44.1705 29.0793 42.1388 29.1214L42.1906 31.6209C45.5826 31.5505 48.2956 28.7807 48.2956 25.3879H45.7956ZM42.1647 29.1211H39.364V31.6211H42.1647V29.1211ZM9.68166 19.1548H6.88094V21.6548H9.68166V19.1548ZM6.85503 19.1551C3.46303 19.2254 0.75 21.9953 0.75 25.388H3.25C3.25 23.3558 4.87508 21.6967 6.90686 21.6545L6.85503 19.1551ZM0.75 25.388C0.75 28.7807 3.46303 31.5506 6.85503 31.621L6.90686 29.1215C4.87508 29.0794 3.25 27.4202 3.25 25.388H0.75ZM6.88094 31.6212H9.68166V29.1212H6.88094V31.6212Z" 
            fill="currentColor" {...props}/>

        </svg>

    )
}

export default GroupIcon;
