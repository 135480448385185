import { v4 as uuidv4 } from 'uuid';
import { connect, useSelector, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import React, { useState, useRef, useEffect } from 'react';
import SendIcon from '../../Icons/Send_2.tsx';
import './Kanban.css';
import Checkbox from './Checkbox';
import './Checkbox.css';
import SelectionBar from '../SelectionBar.jsx';
import useAuth from '../../hooks/useAuth.jsx';
import List from './List.jsx';
import KanbanActionButton from './KanbanActionButton.jsx';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { fetchBoard, DragHappened, moveCard } from '../../reducers/listReducer.js';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { backendURL } from "../../api/axios.js";
import { Icon, Select, MenuItem, InputLabel } from "@mui/material";
import ProjectIcon from '../../Icons/Folder.tsx';
import BoardIcon from '../../Icons/Dashboard.tsx';
import FilterIcon from '../../Icons/Sort.tsx';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import AssigneesIcon from '../../Icons/Assignees.tsx';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import CustomizedTables from './listView.jsx';



const styles = {
  listContainer: {
    display: "flex",
    overflow: "scroll",
    paddingTop: "20px"
  },
  dropDown: {
    backgroundColor: 'unset',
    height: 'max-content',
    outline: '1px solid #d0d1d280',
    borderRadius: 30,
    color: 'black',
    margin: '0px 10px',
    padding: '5px 20px',
    fontFamily: "Raleway",
    boxShadow: 'none'
  }
}

const KanbanComponent = () => {
  const token = Cookies.get('token');
  const decoded = jwtDecode(token);

  const { user } = useAuth();
  const { boardId } = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [projects, setProjects] = useState([]);

  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);



  useEffect(() => {
    const storedOptions = localStorage.getItem('selectedMembers' + boardId);
    console.log("LSF", storedOptions)
    if (storedOptions) {
      setSelectedOptions(JSON.parse(storedOptions));
    }
    const storedProject = localStorage.getItem('selectedProject' + boardId);

    if (storedOptions) {
      setSelectedProject(storedProject);
    }
  }, []);

  useEffect(() => {
    fetch(backendURL + "/boards/" + boardId + "/projects")
      .then(response => response.json())
      .then(data => {
        setProjects(data.map(item => item));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    dispatch(fetchBoard(boardId));
  }, [dispatch]);
  const onDragEnd = result => {
    console.log('drag result', result)
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }
    console.log("before dispatch",
      source.droppableId,
      destination.droppableId,
      source.index,
      destination.index,
      draggableId,
      type
    );

    dispatch(
      DragHappened({
        droppableIdStart: source.droppableId,
        droppableIdEnd: destination.droppableId,
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        draggableId: draggableId,
        type: type
      }
      )
    );
    dispatch(
      moveCard({
        droppableIdStart: source.droppableId,
        droppableIdEnd: destination.droppableId,
        droppableIndexStart: source.index,
        droppableIndexEnd: destination.index,
        draggableId: draggableId,
        type: type
      }
      )
    );
  }
  const lists = useSelector(state => state.list);
  useEffect(() => {
    fetch(backendURL + '/boards/access/' + boardId)
      .then(response => response.json())
      .then(data => {
        setOptions(data.map(item => ({ value: item.id, label: item.name, picture: item.profilePicture })));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const [tabValue, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    const { name, value } = event.target;
    console.log("LS", value);

    // Update the tab value when a tab changes
    setValue(newValue);

    // Additional logic for handling "assignee" and "project" values
    if (name === "assignee") {
      localStorage.setItem('selectedMembers' + boardId, JSON.stringify(value));
      setSelectedOptions(value);
    } else {
      localStorage.setItem('selectedProject' + boardId, value);
      setSelectedProject(value);
    }
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className='kanban-wrapper'>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center' // Ensure vertical alignment
        }}
      >
        {/* Tabs section */}

        <Tabs
          value={tabValue}
          onChange={handleChange}
          sx={{
            '& .MuiTab-root': {
              color: 'gray',
              fontFamily: 'Raleway, sans-serif', // Apply Raleway font to unselected tabs
            },
            '& .MuiTab-root.Mui-selected': {
              color: 'black',
              fontFamily: 'Raleway, sans-serif', // Apply Raleway font to selected tab
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'black'
            },
          }}
        >
          <Tab icon={<GoalIcon />} iconPosition="start" label="Projects" name="tab1" {...a11yProps(0)} />
          <Tab icon={<GoalIcon />} iconPosition="start" label="Boards" name="tab2" {...a11yProps(1)} />
        </Tabs>




        {/* Filters section */}
        <div className='filters' style={{ display: 'flex', alignItems: 'center' }}>
          <AssigneesIcon />
          <Select
            style={styles.dropDown}
            labelId="multi-select-label"
            id="multi-select"
            name="assignee"
            multiple
            value={selectedOptions}
            onChange={handleChange}
            label="Assignees"
            renderValue={(selected) =>
              options.length > 0
                ? selected.map(value => {
                  const option = options.find(option => option.value === value);
                  return option ? option.label : 'Unknown';
                }).join(', ')
                : ''
            }
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          <Select
            style={styles.dropDown}
            labelId="multi-select-label"
            id="multi-select"
            name="project"
            value={selectedProject}
            label="Project"
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="">Project</MenuItem>
            {projects.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Box>


      <CustomTabPanel value={tabValue} index={0}>
        <CustomizedTables />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <div className='list-wrapper'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="test" direction="horizontal" type="list">
              {provided => (
                <div style={styles.listContainer} {...provided.droppableProps} ref={provided.innerRef}>
                  {lists.map((list, index) => (
                    <List listID={list._id} key={list._id} title={list.title} cards={list.cards} index={index} boardId={boardId} selectedmembers={selectedOptions} selectedProject={selectedProject} />
                  ))}
                  {provided.placeholder}
                  <KanbanActionButton list boardId={boardId} />
                </div>
              )}

            </Droppable>
          </DragDropContext>
        </div>
      </CustomTabPanel>


    </div>
  );

}

export default KanbanComponent;

