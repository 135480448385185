import React from 'react';
import CircleImageColumn from './CircleImageColumn';
import './Community.css'; // Import CSS file for styling
import { fetchCommunityBoard, fetchSameOrgUsers, fetchCommunityLists, moveCardBetweenLists, updatedStatus } from '../../api/axios';
import { useState, useEffect } from 'react';
import ChatPage from './ChatPage';
import { useDispatch } from 'react-redux';
import { editCard } from '../../reducers/listReducer';
import Switch from '@mui/material/Switch';
import { Card, CardContent, Typography } from '@mui/material';
import classNames from 'classnames';
import CircleImageRow from './CircleImageRow';
import ViewOnlySidebar from './ViewOnlySidebar';

const styles = {
    card_wrapper: {
        margin: "20px 0px",
        borderRadius: 30,
        boxShadow: 'none',
        backgroundColor: 'red',
        padding: '10px 15px'
        //   '&::WebkitScrollbar': { width: 0, height: 0 }
    },
    card: {
        padding: '10px'
    }
}

const cards = Array.from({ length: 10 }, (_, index) => (
    <Card key={index} className="cardt">
        <CardContent>
            <Typography variant="h5" component="div">
                Card {index + 1}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                This is some sample text for card {index + 1}.
            </Typography>
        </CardContent>
    </Card>
));
const CommunitySection = () => {
    const [users, setUsers] = useState([]);
    const [lists, setLists] = useState([]);
    const userID = localStorage.getItem('user_id');
    const dispatch = useDispatch();
    const [isToggled, setIsToggled] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [members, setMembers] = useState([]);

    const handleCardClick = (card) => {
        setSelectedCard(card);
        setIsDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedCard(null);
        window.location.reload();
    };

    const toggleExpand = (e) => {
        // Prevent click event from propagating to the parent div
        e.stopPropagation();
        setExpanded(!expanded);
    };



    const handleToggle = () => {
        const data = updatedStatus(userID, !isToggled ? "Checked In" : "Checked out")
        //setIsToggled(prevState => !prevState);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const board = await fetchCommunityBoard(userID);
                console.log("b", board._id);
                const data = await fetchCommunityLists(board._id);
                setLists(data.map(item => item));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userID]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchSameOrgUsers(userID);
                setIsToggled(data.find(item => item._id === userID).status === "Checked In");
                setUsers(data.map(item => item));
                setMembers(data.map(item => ({ value: item._id, label: `${item.firstName}  ${item.lastName}`, picture: item.profilePicture })))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // Call fetchData every second
        const intervalId = setInterval(fetchData, 1000);

        // Clear interval on cleanup to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [userID]);

    const handleButtonClick = (card, cardId, index, listId) => {
        const updatedLists = lists.map(list => {
            if (list._id === listId) {
                return {
                    ...list,
                    cards: list.cards.filter(card => card._id !== cardId)
                };
            }
            return list;
        });
        setLists(updatedLists);
        moveCardBetweenLists(cardId, index, listId, "66386e5d135bee17b6371598");
        dispatch(editCard({ id: cardId, listID: listId, card: { ...card, assignee: userID } }));
    };
    return (


        <>
            
            <Switch
                checked={isToggled}
                onChange={handleToggle}
                inputProps={{ 'aria-label': 'controlled' }}
            />

            <div className="parent-container">
                <div className={classNames('peoplediv', { expanded })}
                    onClick={toggleExpand}>
                    {!expanded ? <CircleImageColumn imageUrls={users} /> : <CircleImageRow imageUrls={users} />}
                </div>
            </div>
            <div className="container-wrapper">
                <div className="scrollable-row">
                    <div className="scrollable-container">
                        <h3>
                            Open Items
                        </h3>
                        {lists.length > 0 ? lists[0].cards.map((card, index) =>
                            <Card style={styles.card_wrapper} key={index} className="cardt">
                                {isDialogOpen && selectedCard && selectedCard._id === card._id && (
                                <ViewOnlySidebar
                                    members={members}
                                    onClose={handleCloseDialog}
                                    cardDetails={selectedCard}
                                    id={selectedCard._id}

                                />)}
                                <CardContent style={styles.card} onClick={() => handleCardClick(card)}>
                                    <span className='community-task'>
                                        {card.title}
                                    </span>
                                    <button style={{ marginRight: "auto" }} onClick={(e) => {e.stopPropagation();handleButtonClick(card, card._id, index, card.listId)}}>Take Task</button>
                                </CardContent>
                            </Card>
                        ) : null}
                    </div>
                    <div className="scrollable-container">
                        <h3>
                            Closed Items
                        </h3>
                        {lists.length > 0 ? lists[1].cards.map((card, index) =>

                            <Card style={styles.card_wrapper} key={index} className="cardt">
                                {isDialogOpen && selectedCard && selectedCard._id === card._id && (
                                <ViewOnlySidebar
                                    members={members}
                                    onClose={handleCloseDialog}
                                    cardDetails={selectedCard}
                                    id={selectedCard._id}

                                />)}
                                <CardContent style={styles.card} onClick={() => handleCardClick(card)}>
                                    <span className='community-task'>
                                        {card.title}
                                    </span>
                                </CardContent>
                            </Card>
                        ) : null}
                    </div>
                </div>
                <ChatPage />
            </div>


        </>
    );
};

export default CommunitySection;
