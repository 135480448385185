import React, { useState } from 'react';
import { Grid, TextField, IconButton } from '@mui/material';
import SendIcon from '../../Icons/Send_2.tsx';

import './chatSection2.css';

const ChatFooter = ({ setMessages, messages }) => {
  const [message, setMessage] = useState('');
  const userID = localStorage.getItem('user_id');

  const sendMessage = async (userID, message) => {
    const url = "https://quids.app/inq_cbot/receive_message/";
    const headers = { 'Content-Type': 'application/json' };
    const payload = { user_id: userID, user_message: message };

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (res.ok) {
        const jsonResponse = await res.json();
        setMessages(prevMessages => [
          ...prevMessages,
          { name: "bot", text: jsonResponse.response }
        ]);
      } else {
        const errorResponse = await res.text();
        console.log(errorResponse);
      }
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      setMessages(prevMessages => [
        ...prevMessages,
        { name: "user", text: message }
      ]);
      sendMessage(userID, message);
      setMessage('');
    }
  };

  return (
    <div className="chat__footer">
      <form className="form" onSubmit={handleSendMessage}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              placeholder="I am just starting out, what policies/documents should I be aware of?"
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="message__input"
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton type="submit" className="sendBtn">
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
  
};

export default ChatFooter;
