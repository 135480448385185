import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddBoard.css'
import { backendURL, fetchSameOrgUsers } from "../../api/axios.js";

const AddBoard = () => {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // Manage confirmation dialog
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const userID = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSameOrgUsers(userID);
        setOptions(data.map(item => ({ key: item._id, value: item.firstName })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userID]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleCreate = async () => {
  //   const payload = { title: title, members: selectedOptions, description: description };
  //   console.log("COMMENT", payload);

  //   try {
  //     const response = await fetch(backendURL + "/boards/with-members", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) { 
  //       throw new Error('Failed to create board');
  //     }

  //     // Reload window only after successful response
  //     window.location.reload();
  //   } catch (error) {
  //     // Handle errors if any
  //     console.error("Error:", error);
  //   }
  // };
  const handleCreate = async () => {
    const payload = { title: title, members: selectedOptions, description: description };
    console.log("COMMENT", payload);
  
    try {
      const response = await fetch(backendURL + "/boards/with-members", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create board');
      }
  
      // Alert the user when board is created successfully
      alert("Board created successfully!");
  
      // Reload window after successful response
      window.location.reload();
    } catch (error) {
      // Handle errors if any
      console.error("Error:", error);
      alert("Failed to create the board, please try again."); // Alert in case of error
    }
  };
  
  const handleOptionChange = (e) => {
    const value = e.target.value;
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  // Confirmation Popup Handlers
  const openConfirmationDialog = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmCreate = () => {
    handleCreate();
    closeConfirmationDialog(); // Close confirmation dialog after "Yes"
  };

  return (
    <>
      <button onClick={handleOpen}>Add</button>
      {open && (
        <div className="dialog">
          <div className="dialog-content">
            <label>Title:</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            <label>Description:</label>
            <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
            <label>Select Members:</label>
            <select multiple value={selectedOptions} onChange={handleOptionChange}>
              {options.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.value}
                </option>
              ))}
            </select>
          </div>
          <div className="dialog-actions">
            <button onClick={handleClose}>Cancel</button>
            <button onClick={openConfirmationDialog}>Create</button>
          </div>
        </div>
      )}

      {/* Confirmation Popup */}
      {confirmationOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <p>Are you sure you want to create this board?</p>
            <div className="popup-actions">
              <button onClick={handleConfirmCreate}>Yes</button>
              <button onClick={closeConfirmationDialog}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddBoard;
