import React from 'react';
import { Provider } from 'react-redux';
import { AuthProvider } from './components/Auth/AuthProvider';
import App from './App';
import store from './store';
import Layout from './layouts/Layout';
import LoginLayout from './layouts/LoginLayout';
import ChatComponent from './components/Chat/Chat';
import ProjectsPage from './components/Projects/Projects';
import MindMapComponent from './components/MindMapped/MindMapComponent';
import SemanticSearch from './components/SemanticSearch/Search';
import ProjectDetailPage from './components/Projects/ProjectDetailPage/ProjectDetailPage';
import Meetings from './components/Meetings/Meetings';
import MeetingDetails from './components/Meetings/MeetingDetails';
import MessengerComponent from './components/ChatMessenger/Chat';
import KanbanComponent from './components/Kanban/Kanban';
import CommunitySection from './components/Community/Community'
import AuthPage from './components/Auth/Auth';
import RequireAuth from './components/Auth/RequireAuth';
import Missing from './components/Missing';
import ReactDOM from 'react-dom/client';
// import useAuth from './hooks/useAuth';
import { Navigate,createBrowserRouter, RouterProvider } from 'react-router-dom';
import Cookies from 'js-cookie';

import './index.css';
import "@fontsource/montserrat";
import "@fontsource/montserrat/900.css";
import "@fontsource/raleway";
import "@fontsource/raleway/300.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/900.css";
import Board from './components/Kanban/Board';

const AppComponent = Layout(App);
const EnhancedChatComponent = Layout(ChatComponent);
const MessengerchatComponent = Layout(MessengerComponent);
const KanbanmainComponent = Layout(KanbanComponent); //<KanbanmainComponent/>
const ProjectComponent = Layout(ProjectsPage);
const ProjectDetailsComponent = Layout(ProjectDetailPage);
const MindComponent = Layout(MindMapComponent);
const SemanticSearchComponent = Layout(SemanticSearch);
const AuthPageComponent = LoginLayout(AuthPage);
const MeetingsComponent = Layout(Meetings);
const MeetingDetailsComponent = Layout(MeetingDetails);
const BoardComponent = Layout(Board);
const CommunityComponent = Layout(CommunitySection);


const router = createBrowserRouter(
  [ 
    {path: '/auth', element: <AuthPageComponent/>},
    { path: '/', element: <RequireAuth><AppComponent /> </RequireAuth> },
    { path: '/home', name: 'Home'},
    { path: '/chat', name: 'Chat', element:<RequireAuth><EnhancedChatComponent /></RequireAuth>},
    { path: '/projects', name: 'Projects', element:<RequireAuth><BoardComponent/></RequireAuth>},
    { path: '/projects/:boardId', name: 'Kanban Board', element:<RequireAuth><KanbanmainComponent/></RequireAuth>},
    { path: '/hush-meetings',element:<RequireAuth><MeetingsComponent /></RequireAuth>},
    { path: '/meeting_details/:meetingId',element:<RequireAuth><MeetingDetailsComponent /></RequireAuth>},
    { path: '/community',element:<RequireAuth><CommunityComponent /></RequireAuth>, name: 'Community' },

    // { path: '/messenger', name: 'Messenger', element:<RequireAuth><MessengerchatComponent /></RequireAuth>},
    // { path: '/projects', name: 'Projects', element: <RequireAuth><ProjectComponent /></RequireAuth> },
    // { path: '/projects/:projectName', element:<RequireAuth><ProjectDetailsComponent /></RequireAuth>}, // New route for ProjectDetailPage
    // { path: '/mindmaps', element: <RequireAuth><MindComponent /></RequireAuth>},
    // { path: '/semantic-search',element:<RequireAuth><SemanticSearchComponent /></RequireAuth>},
    
    {path: '*', element:<Missing/>}
  ],
);

const RootApp = () => {
  // const { user, loading } = useAuth(); // Access loading state from AuthContext
  const token = Cookies.get('token');
  const userID = localStorage.getItem('user_id');
  // const tokenExpiration = new Date(Cookies.get('tokenExpiration'));
  const refreshTokenExpiration = new Date(Cookies.get('refreshTokenExpiration'));


  return (
    
      <RouterProvider router={router}>
        {(!token || !userID || refreshTokenExpiration <= new Date() ? (
          <Navigate to="/auth" />
        ) : (
          <RequireAuth>{router}</RequireAuth>
        ))}
      </RouterProvider>

  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
  <AuthProvider> {/* Wrap your entire application with AuthProvider */}
    <RootApp />
  </AuthProvider>
  </Provider>
);

export default router;