import React, { useState, useEffect } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import { backend } from '../../api/axios.js';
import {fetchBoard} from '../../reducers/listReducer.js';
import { useSelector,useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import AddBoard from './AddBoard.jsx';
import './Board.css';


const Board= () => {
  const [boards, setBoards] = useState([]);
  const dispatch = useDispatch();
  const userID = localStorage.getItem('user_id');
  
  const navigate = useNavigate();
  const handleBoardClick = (board) => {
    console.log("B",board)
    navigate(`/projects/${board._id}`, { state: { title: board.title } });
  };
  
  useEffect(() => {
    backend.get(`boards/get-boards/${userID}`)
      .then(response => {
        setBoards(response.data);

      })
      .catch(error => {
        console.error('Error fetching boards:', error);
      });
  }, [userID]);
  return (
    <div className='board-container'>
      {boards.map(board=> (
        <div key={board._id} onClick={() => handleBoardClick(board)} className="board">
          <h2>{board.title}</h2>
        </div>
      ))}
      <AddBoard/>
    </div>
  );
};

export default Board;