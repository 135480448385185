import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TaskIcon from "../../Icons/Task.tsx";
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import { Stack } from '@mui/material';
const rows = [
  { task: 'Design Inquisitive Chat page', status: 'To do', assignee: 'Huda Kamran', due: 'May 9, 2024' },
  { task: 'Design Inquisitive Community page', status: 'To do', assignee: 'Huda Kamran', due: '' },
  { task: 'Inquisitive Home page', status: 'To do', assignee: 'Huda Kamran', due: '' },
];

export default function RoundedTable() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',// Stack title and table vertically
        justifyContent: 'center',// Horizontally center the table
        alignItems: 'flex-start',// Left-align the title
        margin: '20px',// Add margin around the table container
      }}
    >

      <TableContainer
        component={Paper}
        sx={{
          p: 2,
          borderRadius: '40px', // Adjust the radius for rounded corners
          overflow: 'hidden',   // Prevents content overflow
          boxShadow: '0 4px 10px rgba(0,0,0,0.1)', // Optional: Add a subtle shadow for better visuals
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2 }}>
          <GoalIcon />
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            Sub-tasks
          </Typography>
        </Stack>


        <Table sx={{ minWidth: 500 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Task</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Assignee</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Due</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '36px' }} // Adjust row height
              >
                <TableCell component="th" scope="row" sx={{ gap: '8px' }}>
                  <TaskIcon />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.task}
                </TableCell>
                <TableCell align="left">
                  <Chip
                    label={row.status}
                    sx={{
                      backgroundColor: '#0060db',
                    }}
                  />
                </TableCell>
                <TableCell align="left">{row.assignee}</TableCell>
                <TableCell align="left">{row.due || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
