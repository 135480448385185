import React, { useState } from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import ChatBody from './ChatBody'; // Ensure you have this component
import ChatFooter from './ChatFooter'; // Ensure you have this component

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false); // State for toggle

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState); // Toggle the expand state
  };

  return (
    <Box className="chat" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper className="chat__main" elevation={3} sx={{ width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', height: '80vh' }}>

        {/* Chat Header */}
        <Box
          className="chat__header"
          sx={{
            padding: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
          }}
        >
          <Typography variant="h6">Ask Me Anything about Community Resources</Typography>
          <Button className="expand__button" variant="outlined" sx={{ minWidth: '30px' }} onClick={toggleExpand}>
            {isExpanded ? '⮝' : '⮟'} {/* Change icon based on state */}
          </Button>
        </Box>
        {/* Chat Body */}
        <Box sx={{ flexGrow: 1, overflowY: 'auto', height: isExpanded ? '40%' : '60%' }}> {/* Adjust height based on expanded state */}
          <ChatBody messages={messages} />
        </Box>


        {/* Chat Footer */}
        <ChatFooter setMessages={setMessages} messages={messages} />
      </Paper>
    </Box>
  );
};

export default ChatPage;
