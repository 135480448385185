import React from 'react';
import { Box, Typography } from '@mui/material';

const ChatBody = ({ messages }) => {
  return (
    <Box 
      className="message__container" 
      sx={{ 
        padding: 2, 
        overflowY: 'auto', 
        flexGrow: 1, // Allow the Box to grow within the parent container
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end', // Ensure messages start from the bottom
        height: '100%', // Ensure it takes up the full available height
      }}
    >
      {messages.map((message, index) =>
        message.name === 'user' ? (
          <Box className="message__chats" key={index} sx={{ marginBottom: 2, textAlign: 'right' }}>
            <Typography variant="subtitle2" className="sender__name">You</Typography>
            <Box 
              className="message__sender" 
              sx={{ 
                display: 'inline-block', 
                padding: 1, 
                borderRadius: '8px', 
                backgroundColor: '#d1f0d1', 
                maxWidth: '70%', 
                marginLeft: 'auto' 
              }}
            >
              <Typography>{message.text}</Typography>
            </Box>
          </Box>
        ) : (
          <Box className="message__chats" key={index} sx={{ marginBottom: 2, textAlign: 'left' }}>
            <Typography variant="subtitle2">{message.name}</Typography>
            <Box 
              className="message__recipient" 
              sx={{ 
                display: 'inline-block', 
                padding: 1, 
                borderRadius: '8px', 
                backgroundColor: '#e3e3e3', 
                maxWidth: '70%' 
              }}
            >
              <Typography>{message.text}</Typography>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default ChatBody;
