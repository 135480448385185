import React from 'react';
import './CircleImageRow.css'; // Import CSS file for styling
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const CircleImageRow = ({ imageUrls }) => {
  const groupedImages = imageUrls.reduce((acc, user) => {
    const status = user.status;
    acc[status] = acc[status] || [];
    acc[status].push(user);
    return acc;
  }, {});

  return (
    <div className="circle-image-row">
      {Object.entries(groupedImages).map(([status, users]) => (
        <div key={status} className="status-group">
          <h2>{status}</h2>
          <div className="image-row">
            {users.map((user, index) => (
              <div key={index} className="member-wrapper">
                <p>{`${user.firstName}  ${user.lastName}`}</p>
                <div className="circle-image-wrapper">
                  <img
                    src={user.profilePicture}
                    alt={`Image of ${user.name}`} // Use user.name or a meaningful alt text
                    className="circle-image"
                  />
                </div>
                <p className="status">{user.status}</p>
                <div className="status-button">
                  <button onClick={event => {
                    event.stopPropagation(); // <-- this stops the click going through to the parent div
                    console.log(user);
                    toast.success(`Highfived ${user.firstName}  ${user.lastName}`, { autoClose: 3000 });
                    
                  }}>Highfive</button>
                  <button onClick={event => {
                    event.stopPropagation(); // <-- this stops the click going through to the parent div
                    console.log(user);
                    toast.success(`Poked ${user.firstName}  ${user.lastName}`, { autoClose: 3000 });
                    
                  }}>Poke</button>
                </div>
              </div>
            ))}
          </div>
          {/* Add your button logic here, potentially passing user data or status */}

        </div>
      ))}
    </div>
  );
};

export default CircleImageRow;
