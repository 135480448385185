import React from 'react';
import './Assignee_Image.css'; // Import CSS file for styling

const AssigneeImage = ({ imageUrl }) => {
  return (
    <div className="assignee-image-container">
      <img className="assignee-image" src={imageUrl} alt="Assignee" />
    </div>
  );
};

export default AssigneeImage;
