
import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Stack, Typography, IconButton, Input, } from '@mui/material';
import { connect, useSelector, useDispatch } from 'react-redux';
import { editCard, deleteCard } from '../../reducers/listReducer.js';
import TextareaAutosize from 'react-textarea-autosize';
import CommentsSection from './Comment.jsx'
import { backendURL } from "../../api/axios.js";
import { Icon, Checkbox, Select, MenuItem, InputLabel } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import styled from "styled-components";
import './Card.css';
import TaskIcon from "../../Icons/Task.tsx";
import CalendarIcon from "../../Icons/Calendar_Check.tsx";
import GoalIcon from "../../Icons/Bullseye-pointer.tsx";
import UserIcon from "../../Icons/User.tsx";

import DescriptionIcon from "../../Icons/Hamburger.tsx";
import AddIcon from "../../Icons/Circle_Add.tsx";
import RichTextEditor from "./RichTextEditor.jsx";

import CrossIcon from "../../Icons/Cross.tsx";
import ActivityIcon from "../../Icons/Arrow-trend-up.tsx";
import WatchIcon from "../../Icons/Stop Watch.tsx";
import Blockers from "./Blockers.jsx";

import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { autoBatchEnhancer } from "@reduxjs/toolkit";

const styles = {
    dropDown: {
      backgroundColor: 'unset',
      height: 'max-content',
      outline: '1px solid #d0d1d280', 
      borderRadius: 30,
      color: 'black',
      margin: 0,
      padding: '5px 20px',
      fontFamily: "Raleway",
      boxShadow: 'none'
    }
  }
const Sidebar = ({ listID, onClose, cardDetails, handleDeleteCard, handleInputChange, handleCancel, handleSaveCard, handleChange, options, selectedOptions, id, projects, setProjects, selectedProject, setSelectedProject, setCardDetails, idToTitleMapping
}) => 
{
  const tag_options = [
    { value: 'todo', label: 'Todo' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'done', label: 'Done' }
  ];
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const token = Cookies.get('token');
  const decoded = jwtDecode(token);
  console.log("decoded",decoded,cardDetails);
  const reporter = decoded.role==="reporter";

  /*try{
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(cardDetails.description === '' ? 'Add description...' : cardDetails.description)));
  }
  catch{
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
  }*/
  const dispatch = useDispatch();
  const modalRef = useRef();
  const dialogRef = useRef();
  const selectRef = useRef();

  {/*useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('outside',modalRef.current,!modalRef.current.contains(event.target),dialogRef.current,selectRef.current,!selectRef.current.contains(event.target) )
      if (modalRef.current && !modalRef.current.contains(event.target)  &&
      dialogRef.current &&
      !dialogRef.current.contains(event.target) &&
      selectRef.current &&
      !selectRef.current.contains(event.target) ){
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);*/}


  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };


  const handleTitleDoubleClick = () => {
    setIsTitleEditing(true);
  };


  const handleTitleBlur = () => {
    setIsTitleEditing(false);
  };



  const handleDropDownChange = (event) => {
    if (event.target.value === "new") {
      handleOpenDialog()
    }
    else {
      setSelectedProject(event.target.value);
      dispatch(editCard({ id: id, listID: listID, card: { ...cardDetails, project: event.target.value } }));
    }
  };




  const handleCreateProject = async () => {
    // Add your logic to handle creating a new project
    const newProjectName = selectedProject; // This is the name of the new project

    // Call your fetch API here to create the new project
    try {
      const response = await fetch(backendURL + "/boards/" + cardDetails.boardId + "/projects", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ projectId: newProjectName })
      });

      if (!response.ok) {
        throw new Error('Failed to create project');
      }

      setProjects([...projects, newProjectName]);
      console.log('Project created successfully');
    } catch (error) {
      console.error('Error creating project:', error);
      // Handle error gracefully, show a message to the user, etc.
    }
    handleCloseDialog();

  };


  const currentDate = new Date();

  // Get local date and time components
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const milliseconds = currentDate.getMilliseconds();

  // Create a new Date object without GMT offset
  const dateWithoutGMT = new Date(year, month, day, hours, minutes, seconds, milliseconds);

  const [showForm, setShowForm] = useState(false);
  const [newItemData, setNewItemData] = useState({
    title: 'none',
    description: '',
    assignee: options.length > 0 ? options[0].value : '',
    listId: listID,
    dueDate: dateWithoutGMT,
    boardId: cardDetails.boardId,
    tag: 'todo'
  });

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    console.log("inp",name,value,new Date().toISOString())


    setNewItemData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };
  const handleSubTaskDropDown = (e, index) => {
    const { name, value } = e.target;

    setCardDetails(prevState => ({
      ...prevState,
      child: prevState.child.map((child, i) => {
        if (i === index) {
          return {
            ...child,
            tag: value
          };
        }
        return child;
      })
    }));
    const updateTag = async () => {
      try {
        // Fetch request to update the tag of the child (subtask)
        const response = await fetch(`${backendURL}/cards/${cardDetails.child[index]._id}/tag`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tag: value }),
        });

        if (!response.ok) {
          throw new Error('Failed to update tag');
        }

        console.log('Tag updated successfully');
      } catch (error) {
        console.error('Error updating tag:', error.message);
      }
    };

    // Call the inner asynchronous function
    updateTag();
  };

  const handleAddItemClick = () => {
    setShowForm(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Create a new child card
    try {
      console.log("New card", newItemData)
      const newCard = await createChildCard(cardDetails._id, {
        title: newItemData.title,
        description: newItemData.description,
        listId: newItemData.listId,
        boardId: cardDetails.boardId,
        assignee: [newItemData.assignee],
        dueDate: new Date(newItemData.dueDate).toISOString(),
        // Add other properties as needed
      });


      // Update state with the new card
      setCardDetails(prevState => ({
        ...prevState,
        child: [...prevState.child, newCard]
      }));

      // Reset the form data and hide the form
      setNewItemData({
        title: 'none',
        description: '',
        assignee: options[0].value,
        listId: listID,
        dueDate: new Date(),
        boardId: cardDetails.boardId,
        tag: 'todo'
      });
      setShowForm(false);
    } catch (error) {
      console.error('Error creating child card:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const createChildCard = async (parentId, cardData) => {
    try {
      console.log("New card", parentId, cardData)
      const response = await fetch(`${backendURL}/cards/${parentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cardData),
      });
      if (!response.ok) {
        throw new Error('Failed to create child card');
      }
      return await response.json();
    } catch (error) {
      console.error('Error creating child card:', error);
      throw error; // You might want to handle errors more gracefully in your application
    }
  };


return (
<div className="backdrop">
  <Box sx={{ p: 2, height: '100vh', overflow: 'auto' }}>
    <Box ref={modalRef} sx={{ maxWidth: 1000, mx: 'auto', p: 2, backgroundColor: 'white', borderRadius: 2, overflow:'auto'}}>
      {/* TASK TITLE */}
      <Grid container alignItems="center" sx={{ mb: 2 }}>
        <Grid item>
          <TaskIcon />
        </Grid>
        <Grid item sx={{ ml: 2, flexGrow: 1 }}>
          {isTitleEditing ? (
            <TextareaAutosize
              minRows={2}
              style={{ width: '100%' }}
              placeholder="Title"
              value={cardDetails.title}
              onChange={handleInputChange}
              autoFocus
            />
          ) : (
            <Typography variant="h5" onDoubleClick={handleTitleDoubleClick} onBlur={handleTitleBlur}>
              {cardDetails.title}
            </Typography>
          )}
        </Grid>
      </Grid>
      {/* Other form sections (PROJECT, ASSIGNEE, DUE DATE, etc.) */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* PROJECT */}
          <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <Grid item>
              <GoalIcon sx={{ fontSize: 32 }} />
            </Grid>
            <Typography sx={{ minWidth: '100px' }}>Project</Typography>
            <Select
              value={selectedProject}
              onChange={handleDropDownChange}
              fullWidth
              ref={selectRef}
              sx={{
                flexGrow: 1,
                minWidth: 200,
                height: '40px', 
                borderRadius: '8px',
                padding: '0 10px',
                backgroundColor: '#f5f5f5',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
            >
              {projects.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              <MenuItem value="new">Create new project</MenuItem>
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* ASSIGNEE */}
          <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <Grid item>
              <UserIcon sx={{ fontSize: 32 }} />
            </Grid>
            <Typography sx={{ minWidth: '100px' }}>Assignee</Typography>
            {reporter ? (
              <Select
                multiple
                value={selectedOptions}
                onChange={handleChange}
                ref={selectRef}
                sx={{
                  flexGrow: 1,
                  minWidth: 200,
                  height: '40px',
                  borderRadius: '8px',
                  padding: '0 10px',
                  backgroundColor: '#f5f5f5',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography sx={{ flexGrow: 1 }}>
                {options.length > 0
                  ? selectedOptions
                      .map((value) => options.find((option) => option.value === value).label)
                      .join(', ')
                  : ''}
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* DUE DATE */}
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <Grid item>
              <CalendarIcon sx={{ fontSize: 32 }} />
            </Grid>
            <Typography sx={{ minWidth: '100px' }}>Due Date</Typography>
            <input
                      type="datetime-local"
                      id="dueDate"
                      name="dueDate"
                      value={cardDetails.dueDate ? cardDetails.dueDate.slice(0, 16) : ''}
                      onChange={handleInputChange}
                      className="form-control"
                      style={{
                        flexGrow: 1,
                        minWidth: '200px',
                        height: '40px',
                        borderRadius: '8px',
                        padding: '0 10px',
                        border: 'none',
                        backgroundColor: '#f5f5f5',
                      }}
                    />
          </Stack>
        </Grid>


        {/* XP */}
        {reporter && (
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
              <Grid item>
                <UserIcon sx={{ fontSize: 32 }} />
              </Grid>
              <Typography sx={{ minWidth: '100px' }}>XP</Typography>
              <Input
                id="xp"
                name="xp"
                type="number"
                value={cardDetails.xp || 0}
                onChange={handleInputChange}
                fullWidth
                disableUnderline
                sx={{
                  flexGrow: 1,
                  minWidth: '200px',
                  height: '40px',
                  borderRadius: '8px',
                  padding: '0 10px',
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: '#f5f5f5',
                  '& input': {
                    outline: 'none',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                }}
              />
            </Stack>
          </Grid>
        )}
      </Grid>
      <hr />
      {/* DESCRIPTION */}
      <Box sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <DescriptionIcon />
          <Typography variant="h6">Description</Typography>
        </Stack>
        <RichTextEditor cardDetails={cardDetails} setCardDetails={setCardDetails} editable={reporter} />
      </Box>
      <hr />
      {/* OTHER FIELDS */}
      {/* SUB-TASKS */}
      <Box sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <DescriptionIcon />
          <Typography variant="h6">Sub-tasks</Typography>
        </Stack>
        <form onSubmit={handleFormSubmit}>
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: 40 }}></th>
                <th style={{ width: '40%' }}>Description</th>
                <th>Assignee</th>
                <th>Status</th>
                <th>Due</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cardDetails.child.map((child, index) => (
                <tr key={index}>
                  <td><TaskIcon /></td>
                  <td>{child.description}</td>
                  <td>{child.assignee.length > 0 && options.length > 0 ? options.find(option => option.value === child.assignee[0]).label : ''}</td>
                  <td>
                    <Select
                      id={`sub-select-${index}`}
                      value={child.tag}
                      onChange={(e) => handleSubTaskDropDown(e, index)}
                      className="form-select"
                      style={{ width: '100%', height: '40px' }}
                    >
                      {tag_options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td>{new Date(child.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td>
                  <td></td>
                </tr>
              ))}

              {!showForm ? (
                <tr>
                  <td colSpan={6}>
                    <span onClick={handleAddItemClick} className="new-button" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                      <AddIcon /> New
                    </span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td><TaskIcon /></td>
                  <td>
                    <input
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={newItemData.description}
                      onChange={handleInputChange2}
                      className="form-control"
                      style={{ height: '40px' }}
                    />
                  </td>
                  <td>
                    <Select
                      id="sub-select"
                      name="assignee"
                      value={newItemData.assignee}
                      onChange={handleInputChange2}
                      className="form-select"
                      style={{ width: '100%', height: '40px' }}
                    >
                      {options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Select
                      id="sub-select-tag"
                      name="tag"
                      value={newItemData.tag}
                      onChange={handleInputChange2}
                      className="form-select"
                      style={{ width: '100%', height: '40px' }}
                    >
                      {tag_options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <input
                      type="datetime-local"
                      name="dueDate"
                      value={newItemData.dueDate}
                      onChange={handleInputChange2}
                      className="form-control"
                      style={{ height: '40px' }}
                    />
                  </td>
                  <td>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        type="submit"
                        size="small"
                        sx={{
                          padding: '6px',
                          borderRadius: '50%',
                          backgroundColor: '#f5f5f5',
                          '&:hover': {
                            backgroundColor: '#e0e0e0',
                          },
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => setShowForm(false)}
                        sx={{
                          padding: '6px',
                          borderRadius: '50%',
                          backgroundColor: '#f5f5f5',
                          '&:hover': {
                            backgroundColor: '#e0e0e0',
                          },
                        }}
                      >
                        <CrossIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </form>
      </Box>
      <hr />
      {/* Blockers */}
      <Box sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <DescriptionIcon />
          <Typography variant="h6">Blockers</Typography>
        </Stack>
        <Blockers cardId={cardDetails._id} userName={decoded.username} reporterId={cardDetails.reporter} userId={decoded.sub} />
      </Box>
      <hr />
      {/* COMMENTS */}
      <Box sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ActivityIcon />
          <Typography variant="h6">Activity</Typography>
        </Stack>
        <CommentsSection comment={cardDetails.comments} id={id} users={options} />
      </Box>
      <hr />
      
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <WatchIcon />
            <Typography>Elapsed Time</Typography>
            <Input
              id="expectedHours"
              name="expectedHours"
              type="number"
              value={cardDetails.expectedHours}
              onChange={handleInputChange}
              disableUnderline
              sx={{
                width: '80px', // Set width to match the other input
                height: '40px',
                borderRadius: '8px',
                padding: '0 10px',
                border: 'none',
                boxShadow: 'none',
                backgroundColor: '#f5f5f5',
                '& input': {
                  outline: 'none',
                },
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
            />
          </Stack>
        </Grid>
        
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <WatchIcon />
            <Typography>Estimated Time</Typography>
            <Input
              id="actualHours"
              name="actualHours"
              type="number"
              value={cardDetails.actualHours}
              onChange={handleInputChange}
              disableUnderline
              sx={{
                width: '80px', // Same width as Elapsed Time input
                height: '40px',
                borderRadius: '8px',
                padding: '0 10px',
                border: 'none',
                boxShadow: 'none',
                backgroundColor: '#f5f5f5',
                '& input': {
                  outline: 'none',
                },
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
            />
          </Stack>
        </Grid>
      </Grid>


      {/* OTHER FIELDS End */}
      {/* DELETE, CLOSE, SAVE BUTTONS */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, mb: 4 }}>
        <button onClick={handleDeleteCard}>Delete Task</button>
        <Stack direction="row" spacing={2}>
          <button onClick={onClose}>Save</button>
        </Stack>
      </Box>
    </Box>
  </Box>
</div>
);};
export default Sidebar;