import React from 'react'
import { NavLink , useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import './Sidebar.css';
import logo from '../Icons/inquidsitive-logo1.png';
import ChatIcon from '../Icons/Comments_Alt_Lines.tsx';
import ProjectIcon from '../Icons/Folder.tsx';
// import MindmapIcon from '../Icons/Cloud_Bolt.tsx';
// import SearchIcon from '../Icons/Search_5.tsx';
// import MeetingsIcon from '../Icons/Users_2';
import HomeIcon from '../Icons/Home_10.tsx';
import GroupIcon from '../Icons/Users_2';
import CommunityIcon from '../Icons/People_Group.tsx';
import LogoutIcon from '../Icons/LogoutIcon';

const SideBar = () => {
const navigate = useNavigate();
const { user, logout } = useAuth();
  // Logout function to clear session and redirect
  const handleLogout = () => {
    logout();
    // Redirect to login page or home
    navigate('/auth');
  };
  return (
    <div className="sidebar">
      <div className="logo-container">
        <img className="logo-container" src={logo} alt="logo"></img>
      </div>
      <div className='nav-links'>
      <div className='link'>
          <NavLink to='/home'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <HomeIcon height="45px" className="white"></HomeIcon>
            Home
          </NavLink>
        </div>
        <div className='link'>
          <NavLink to='/projects'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <ProjectIcon height="45px" className="white"></ProjectIcon>
            Projects
          </NavLink>
        </div>
        <div className='link'>
          <NavLink to='/hush-meetings'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <GroupIcon className="white"></GroupIcon>
            Meetings
          </NavLink>
        </div>
        <div className='link'>
          <NavLink to='/chat'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <ChatIcon height="45px" className="white"></ChatIcon>
            Chat
          </NavLink>
        </div>
        <div className='link'>
          <NavLink to='/community'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <CommunityIcon className="white"></CommunityIcon>
            Community
          </NavLink>
        </div>
        <div className='link'>
          {user && 
            <div className='nav-link logout-link' onClick={handleLogout}>
              <LogoutIcon className="white"/>
              Logout
            </div>
          }
        </div>

        {/* <div className='link'>
          {user && 
          <div to='/auth' className='nav-link' onClick={handleLogout}>
            <LogoutIcon className="white"/>
          
            Logout
          </div>}
        </div> */}
        {/* <div className='link'>
          <NavLink to='/mindmaps'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <MindmapIcon className="white"></MindmapIcon>
            Mindmaps
          </NavLink>
        </div> */}
        {/* <div className='link'>
          <NavLink to='/semantic-search'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <SearchIcon className="white"></SearchIcon>
            Semantic Search
          </NavLink>
        </div> */}
        {/* <div className='link'>
          <NavLink to='/settings'
            className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
          >
            <SettingsIcon className="white"></SettingsIcon>
            Settings
          </NavLink>
        </div> */}

      </div>
    </div>
  )
}

export default SideBar