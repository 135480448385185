import React from 'react';
import './CircleImageColumn.css'; // Import CSS file for styling

const CircleImageColumn = ({ imageUrls }) => {
  return (
    <div className="circle-image-column">
      {imageUrls.map((user, index) => (
        <div className='member-wrapper'>
        <div className="circle-image-wrapper" key={index}>
          <img src={user.profilePicture} alt={`Image ${index + 1}`} className="circle-image" />
          
        </div>
        <p className="status"> {user.status}</p>
        </div>
        
      ))}
    </div>
  );
};

export default CircleImageColumn;
