import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import React, { useState, useRef, useEffect } from 'react';
import SendIcon from '../../Icons/Send_2.tsx';
import './Chat.css';
import Checkbox from './Checkbox';
import './Checkbox.css';
import SelectionBar from '../SelectionBar';
import useAuth from '../../hooks/useAuth';
import { backend,mlend } from '../../api/axios';

const ChatComponent = () => {
    const {user} = useAuth();
    const [input, setInput] = useState(''); //input message in the chat
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);

    const pressTimer = useRef();
    const longPressDuration = 1000; // 1 second
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [selectedMessages,setSelectedMessages] = useState([]);
    const [selectedProject, setSelectedProject] = useState(''); // Store selected project
    const [selectedDocument, setSelectedDocument] = useState(''); // Store selected document


    const handleInputChange = (e) => {
        setInput(e.target.value);
        adjustTextareaHeight(e.target);
      };

    useEffect(() => {
        console.log("Show Checkboxes: ",showCheckboxes);
        if (showCheckboxes === false && messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
      }, [messages,showCheckboxes]);

    useEffect(() => {
        localStorage.setItem('selectedProject',selectedProject);
    }, [selectedProject]);

    const handleSelectionChange = async (project, document,projectID) => {
        setSelectedProject(project);
        setSelectedDocument(document);

        if (project!=='None'){
            console.log("GOING TO GET THE MESSAGES");
            fetchMessages(project);}
        else{
            setMessages([]);
        }
    };
    
  
        const fetchMessages = async (project) => {
          try {
            const response = await backend.get(`user/${user}/get-messages/${project}`, {
              headers: { 'accept': 'application/json' }
            });
            console.log("Messages",response.data);
            const messagesData = response.data.messages;
            setMessages(messagesData);
      
            console.log("Messages for this user's project: ", messagesData);
          } catch (error) {
            console.error('Error fetching chat history: ', error);
          }
        };
      
    
    // console.log('Selected Project:', selectedProject);
    // console.log('Selected ProjectID:', selectedProjectID);
    // console.log('Selected Document:', selectedDocument);

    const handleSend = async () => {
        if (!input) return;

        const userMessage = {
            temp_id:uuidv4(),
            user_id: user,
            project_name:selectedProject,
            documents: [selectedDocument], 
            content: input,
            isMessageSelected:false,
            isUser: true,
        };
        console.log("Adding User Message to Messages",userMessage);
        setMessages([...messages, userMessage]);

        try {            
            const messageToBackend = {
                user_id:userMessage.user_id,
                content:userMessage.content,
                project_name:selectedProject,
                documents:[selectedDocument]
            }

            setInput('');

            console.log("Sending this to backend: ",messageToBackend);
            const response = await backend.post(`chat/chat-request`, messageToBackend);
            console.log("Got repsonse: ",response);

            const userRequest = response.data.user_request;

            const updatedUserMessage = {
                id:userRequest._id,
                user_id: userRequest.user_id,
                content: userRequest.content,
                isMessageSelected: userRequest.isMessageSelected,
                topic: userRequest.topic,
                project_name: userMessage.project_name, // Use the original project name
                documents: userMessage.documents,
                isUser: true, // Hardcode this to true
            };

            setMessages(oldMessages => {
                const updatedMessages = oldMessages.map(message => {
                    if (message.temp_id === userMessage.temp_id) {
                        console.log("comparing: ",message,userMessage);
                        return updatedUserMessage; // Replace the old user message with the updated one
                    }
                    return message;
                });
                return updatedMessages;
            });
            const botResponse = response.data.bot_response;
            // got response from backend 
            const backendReply = {
                id: botResponse._id, // New UUID
                user_id: botResponse.user_id,
                isUser: false,
                isMessageSelected: botResponse.isMessageSelected,
                topic: botResponse.topic,
                documents: userMessage.documents, // Keep the original document for now
                content: botResponse.content,
                project_name: userMessage.project_name, 
            };
            //now added it to our messages
            console.log("Adding Message to Messages:", backendReply);
            setMessages(oldMessages => [...oldMessages, backendReply]);
        } catch (error) {
            console.error("Error sending message:", error);
        }

        setInput('');  // Clear the input after sending.
    };

    const toggleMessageSelection = (id) => {
        const newMessages = messages.map(msg => {
            const currentId = msg._id ? msg._id : (msg.id ? msg.id : msg.temp_id);
            if (currentId === id) {
                return {
                    ...msg,
                    isMessageSelected: !msg.isMessageSelected
                };
            }
            return msg;
        });
        setSelectedMessages([]);
        setMessages(newMessages);
    };
    
    

    const handleMouseDown = () => {
        clearTimeout(pressTimer.current);
        pressTimer.current = setTimeout(() => setShowCheckboxes(true), longPressDuration);
        console.log(messages);
    };

    const handleMouseUp = () => {
        clearTimeout(pressTimer.current);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault(); // Prevent the default behavior (form submission)
          handleSend(); // Call the handleSend function when Enter is pressed
        } else if (e.key === 'Enter' && e.shiftKey) {
          // Insert a newline character when Shift + Enter is pressed
          setInput((prevInput) => prevInput + '\n');
        }
      };

      const adjustTextareaHeight = (textarea) => {
        textarea.style.height = 'auto'; // Reset the height
        const contentHeight = textarea.scrollHeight;
        const visibleHeight = textarea.clientHeight;
    
        if (contentHeight > visibleHeight) {
          textarea.style.height = `${contentHeight}px`; // Set the height to fit the content
        }
      };

    const handleCancelSelection = () => {
        console.log("Selected Messages Before: ",selectedMessages);
        setSelectedMessages([]); // Clear selected messages
        console.log("Selected Messages After: ",selectedMessages);
        const newMessages = messages.map(msg => ({
            ...msg,
            isMessageSelected: false // Clear the selected state for all messages
        }));
        setMessages(newMessages); // Update the messages to clear the selected state
        setShowCheckboxes(false);
    };
    
    const handleSendSelected = async () => {
        console.log("ALL MESSAGES ARE: ",messages);
        const selectedMessages = messages.filter(msg => msg.isMessageSelected);
        try {
            // Call the backend
            // const requestData = {
            //     userId:user,
            //     projectName:selectedProject,
            //     documents:[selectedDocument],
            //     selectedMessageIds: selectedMessages.map(msg => (msg._id))
            // };

            const requestData = {
                nodes: selectedMessages.map(msg=> ({
                    id: msg.id ? msg.id : msg._id,
                    content:msg.content,
                    user_id:msg.user_id,
                    isUser:msg.isUser,
                    project_name:msg.project_name,
                    documents:msg.documents,
                    topic:msg.topic,
                    selected:msg.isMessageSelected})),
                project_name: selectedProject,
                user_id:user,
                documents:[selectedDocument]
            }

            console.log('Selected messages sent to backend',requestData);
            mlend.post('generate-mindmap',requestData,{
            headers: { 'accept': 'application/json' }})
            .then (response=> {console.log('Stored Selected Project: ',response.data)
            console.log('Selected messages sent to backend',response);})
            .catch (error=> {console.log('Error saving selected project name: ',error)})

            

            // if (response.data){
            //     localStorage.setItem('mindmapID',response.data.mindmapId);
            //     localStorage.setItem('project',selectedProject);
            // }
        } catch (error) {
            console.error("Error sending selected messages:", error);
        }
        
        setShowCheckboxes(false); // Hide checkboxes after sending
    };


    return (
        <div className="main-page-container">
            <div className="projects-container">
            <SelectionBar onSelectionChange={handleSelectionChange} />

            </div>
            {showCheckboxes && (
                <div className="button-container">
                    <button className="text-link" onClick={handleSendSelected}>
                        Add to mindmap
                    </button>
                    <button className="text-link" onClick={handleCancelSelection}>
                        Cancel
                    </button>
                </div>
            )}
            <div className="chat-container">
                <div className="messages" ref={messagesEndRef}>
                    {messages.map(message => (
                        <div
                            key={message._id}
                            className={`message-row ${message.isUser ? 'user' : 'backend'} ${showCheckboxes ? 'checkbox-on' : '' }`}
                        >
                            {/* Display checkboxes outside of the message */}
                            {showCheckboxes && (
            <div className="checkbox-container">
              <Checkbox
                checked={message.isMessageSelected}
                onChange={() => toggleMessageSelection(message._id || message._temp_id || message.id)}
              />
            </div>
          )}
                            <div className={`message ${message.isUser ? 'user' : 'backend'}`}
                                onMouseDown={handleMouseDown} // Pass the message ID to handleMouseDown
                                onMouseUp={handleMouseUp}
                            >
                                <div className={`message-text ${message.isUser && 'preserve-line-breaks'}`}>
                                <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='input-container'>
                    <textarea
                    type="text"
                    className="chat-input"
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder="Type your message..."
                    />
                    <button className="chat-button" onClick={handleSend}>
                    <SendIcon className="white" />
                    </button>
                </div>
                
            </div>
        </div>
    );
                        }    

export default ChatComponent;
